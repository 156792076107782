import GQLCodeSnippet, { GQLOpen, GQLClose, GQLLine, GQLTab, GQLName, GQLKeyword, GQLAttribute, GQLOperator, GQLComment, GQLLink } from "../../../../src/components/GQLCodeSnippet/GQLCodeSnippet";
import * as React from 'react';
export default {
  GQLCodeSnippet,
  GQLOpen,
  GQLClose,
  GQLLine,
  GQLTab,
  GQLName,
  GQLKeyword,
  GQLAttribute,
  GQLOperator,
  GQLComment,
  GQLLink,
  React
};