// extracted by mini-css-extract-plugin
export var attribute = "GQLCodeSnippet-module--attribute--2cd07";
export var cdsAssistiveText = "GQLCodeSnippet-module--cds--assistive-text--472e3";
export var cdsHideFeedback = "GQLCodeSnippet-module--cds--hide-feedback--4d959";
export var cdsLayoutConstraintDensity__defaultCondensed = "GQLCodeSnippet-module--cds--layout-constraint--density__default-condensed--e2711";
export var cdsLayoutConstraintDensity__defaultNormal = "GQLCodeSnippet-module--cds--layout-constraint--density__default-normal--735bb";
export var cdsLayoutConstraintDensity__maxCondensed = "GQLCodeSnippet-module--cds--layout-constraint--density__max-condensed--052ea";
export var cdsLayoutConstraintDensity__maxNormal = "GQLCodeSnippet-module--cds--layout-constraint--density__max-normal--db1f4";
export var cdsLayoutConstraintDensity__minCondensed = "GQLCodeSnippet-module--cds--layout-constraint--density__min-condensed--d851d";
export var cdsLayoutConstraintDensity__minNormal = "GQLCodeSnippet-module--cds--layout-constraint--density__min-normal--c2f11";
export var cdsLayoutConstraintSize__default2xl = "GQLCodeSnippet-module--cds--layout-constraint--size__default-2xl--3f57d";
export var cdsLayoutConstraintSize__defaultLg = "GQLCodeSnippet-module--cds--layout-constraint--size__default-lg--700b0";
export var cdsLayoutConstraintSize__defaultMd = "GQLCodeSnippet-module--cds--layout-constraint--size__default-md--3f36c";
export var cdsLayoutConstraintSize__defaultSm = "GQLCodeSnippet-module--cds--layout-constraint--size__default-sm--be1d6";
export var cdsLayoutConstraintSize__defaultXl = "GQLCodeSnippet-module--cds--layout-constraint--size__default-xl--09af4";
export var cdsLayoutConstraintSize__defaultXs = "GQLCodeSnippet-module--cds--layout-constraint--size__default-xs--08402";
export var cdsLayoutConstraintSize__max2xl = "GQLCodeSnippet-module--cds--layout-constraint--size__max-2xl--28908";
export var cdsLayoutConstraintSize__maxLg = "GQLCodeSnippet-module--cds--layout-constraint--size__max-lg--4a5cb";
export var cdsLayoutConstraintSize__maxMd = "GQLCodeSnippet-module--cds--layout-constraint--size__max-md--f3ebb";
export var cdsLayoutConstraintSize__maxSm = "GQLCodeSnippet-module--cds--layout-constraint--size__max-sm--e9346";
export var cdsLayoutConstraintSize__maxXl = "GQLCodeSnippet-module--cds--layout-constraint--size__max-xl--fcbd5";
export var cdsLayoutConstraintSize__maxXs = "GQLCodeSnippet-module--cds--layout-constraint--size__max-xs--6c45b";
export var cdsLayoutConstraintSize__min2xl = "GQLCodeSnippet-module--cds--layout-constraint--size__min-2xl--f69d3";
export var cdsLayoutConstraintSize__minLg = "GQLCodeSnippet-module--cds--layout-constraint--size__min-lg--ab848";
export var cdsLayoutConstraintSize__minMd = "GQLCodeSnippet-module--cds--layout-constraint--size__min-md--9268c";
export var cdsLayoutConstraintSize__minSm = "GQLCodeSnippet-module--cds--layout-constraint--size__min-sm--28657";
export var cdsLayoutConstraintSize__minXl = "GQLCodeSnippet-module--cds--layout-constraint--size__min-xl--882a2";
export var cdsLayoutConstraintSize__minXs = "GQLCodeSnippet-module--cds--layout-constraint--size__min-xs--519ef";
export var cdsLayoutDensityCondensed = "GQLCodeSnippet-module--cds--layout--density-condensed--4d4b7";
export var cdsLayoutDensityNormal = "GQLCodeSnippet-module--cds--layout--density-normal--0ac92";
export var cdsLayoutSize2xl = "GQLCodeSnippet-module--cds--layout--size-2xl--c778a";
export var cdsLayoutSizeLg = "GQLCodeSnippet-module--cds--layout--size-lg--e3493";
export var cdsLayoutSizeMd = "GQLCodeSnippet-module--cds--layout--size-md--d202f";
export var cdsLayoutSizeSm = "GQLCodeSnippet-module--cds--layout--size-sm--a3755";
export var cdsLayoutSizeXl = "GQLCodeSnippet-module--cds--layout--size-xl--73d1f";
export var cdsLayoutSizeXs = "GQLCodeSnippet-module--cds--layout--size-xs--30b05";
export var cdsShowFeedback = "GQLCodeSnippet-module--cds--show-feedback--e559f";
export var cdsSkeleton = "GQLCodeSnippet-module--cds--skeleton--7e88a";
export var cdsVisuallyHidden = "GQLCodeSnippet-module--cds--visually-hidden--8fadc";
export var comment = "GQLCodeSnippet-module--comment--1b5de";
export var dark = "GQLCodeSnippet-module--dark--14a8e";
export var gqlCodeSnippet = "GQLCodeSnippet-module--gqlCodeSnippet--4daf0";
export var keyword = "GQLCodeSnippet-module--keyword--96b00";
export var line = "GQLCodeSnippet-module--line--7884f";
export var link = "GQLCodeSnippet-module--link--3761d";
export var operator = "GQLCodeSnippet-module--operator--c253d";
export var tab = "GQLCodeSnippet-module--tab--326d8";